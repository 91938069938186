window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

window.passwordInputs = function passwordInputs() {
  if (document.querySelector('.password-input')) {
    const passwordInputsArr = document.querySelectorAll('.password-input');
    passwordInputsArr.forEach((password) => {
      const btn = password.querySelector('.password-input__btn');
      const btnIcon = password.querySelector('.password-input__btn i');
      const input = password.querySelector('input');

      btn.addEventListener('click', () => {
        inputIconFunc();
      });

      function inputIconFunc() {
        if (!btn.classList.contains('is-password-hidden')) {
          btn.classList.add('is-password-hidden');
          input.type = 'password';
          btnIcon.classList.add('icon-not-show');
          btnIcon.classList.remove('icon-show');
        } else {
          btn.classList.remove('is-password-hidden');
          input.type = 'text';
          btnIcon.classList.remove('icon-not-show');
          btnIcon.classList.add('icon-show');
        }
      }
      inputIconFunc();
    });
  }
};
passwordInputs();
