const tabs = document.querySelectorAll('.accreditations-tab'),
      contents = document.querySelectorAll('.accreditations-content');

if(tabs.length && contents.length) {
  tabs[0].classList.add('active')
  contents[0].classList.add('active')

  tabs.forEach((tab, i) => {
    tab.addEventListener('click', () => {
      if(tab.classList.contains('active')) return
  
      tabs.forEach(t => t.classList.remove('active'))
      contents.forEach(c => c.classList.remove('active'))
      tab.classList.add('active')
      contents[i].classList.add('active')
    })
  })
}
