window.uploadFile = function uploadFile() {
  if (document.querySelector('[data-upload-file-animation]')) {
    const uploadFileBlocksArr = document.querySelectorAll(
      '[data-upload-file-animation]'
    );
    uploadFileBlocksArr.forEach((fileUpload) => {
      fileUpload.addEventListener('dragover', function () {
        this.classList.add('drag');
        this.classList.remove('drop', 'done');
      });

      fileUpload.addEventListener('dragleave', function () {
        this.classList.remove('drag');
      });

      fileUpload.addEventListener('drop', start, false);
      fileUpload.addEventListener('change', start, false);

      function start() {
        this.classList.remove('drag');
        this.classList.add('drop');
        setTimeout(() => this.classList.add('done'), 500);
      }
    });
  }
};

// meta-upload-file__name
if (document.querySelector('[data-upload-file-animation]')) {
  const uploadParent = document.querySelector('[data-upload-file-animation]');
  const uploadFileName = uploadParent.querySelector('.meta-upload-file__name');
  const inputFile = uploadParent.querySelector('input[type="file"]');

  inputFile.addEventListener('change', function() {
    if (inputFile.files && inputFile.files.length > 0) {
      uploadFileName.textContent = inputFile.files[0].name;
      uploadFileName.classList.add('active');
    } else {
      uploadFileName.textContent = '';
      uploadFileName.classList.remove('active');
    }
  });
}


uploadFile();
