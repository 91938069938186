const cards = document.querySelectorAll('.case-studies__card');

if(cards.length) {
  const calcLine = () => {
    cards.forEach(card => {
      const cardTitle = card.querySelector(".case-studies__card-title"),
            cardDescr = card.querySelector('.case-studies__card-descr');
  
      if(cardTitle.clientHeight > 35) {
        cardDescr.style.setProperty('--max-line-num', '2')
      } else {
        cardDescr.style.setProperty('--max-line-num', '3')
      }
    })
  }
  window.addEventListener('resize', calcLine)
  calcLine()
}