const elements = document.querySelectorAll('[data-parallax]');
const layer = document.querySelector('#site');

// IntersectionObserver callback
const observerCallback = (entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('visible');
      observer.unobserve(entry.target); // Stop observing after it becomes visible
    }
  });
};

// Create an IntersectionObserver instance
const observer = new IntersectionObserver(observerCallback, {
  threshold: 0.1 // Trigger when 10% of the element is in view
});

// Start observing elements
elements.forEach(element => {
  if(!element.classList.contains('static')) {
    observer.observe(element);
  }
});

layer.addEventListener('mousemove', function(e) {
  const ivalueX = (e.pageX * -1 / 130);
  const ivalueY = (e.pageY * -1 / 160);
  // const cvalueX = (e.pageX * -1 / 80);
  // const cvalueY = (e.pageY * -1 / 95);

  elements.forEach((item) => {
    if(item.getAttribute('data-parallax') === 'x') {
      item.style.transform = 'translate3d(' + ivalueX + 'px,' + '0px, 0) scale(1)';
    } else if(item.getAttribute('data-parallax') === 'y') {
      item.style.transform = 'translate3d(' + '0px,' + ivalueY + 'px, 0) scale(1)';
    } else {
      item.style.transform = 'translate3d(' + ivalueX + 'px,' + ivalueY + 'px, 0) scale(1)';
    }
  });

  // const camera = document.getElementById('camera');
  // if (camera) {
  //   camera.style.transform = 'translate3d(' + cvalueX + 'px,' + cvalueY + 'px, 0) scale(1)';
  // }
});
