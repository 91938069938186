// tabs

const tabs = document.querySelectorAll('.work-type-tab'),
      descktopContent = document.querySelectorAll('.work-type-text');

if(descktopContent.length) {
    descktopContent[0].style.maxHeight = `${descktopContent[0].scrollHeight}px`
}
const tabToggle = (tab, i) => {
  if(tab.classList.contains('active')) return

  tabs.forEach(t => {
    t.classList.remove('active')
    t.querySelector('.work-type-tab__body').classList.remove('active')
    t.style.maxHeight = '64px'
  })

  tab.classList.add('active')
  tab.querySelector('.work-type-tab__body').classList.add('active')
  if(window.innerWidth <= 860) tab.style.maxHeight = `${tab.scrollHeight + 15}px`

  descktopContent.forEach(c => {
    c.classList.remove('active')
    c.style.maxHeight = '0px'
  })
  descktopContent[i].classList.add('active')
  descktopContent[i].style.maxHeight = `${descktopContent[i].scrollHeight}px`
}

tabs.forEach((tab, i) => {
  tab.addEventListener('click', () => tabToggle(tab, i))
})

window.addEventListener('resize', () => {
  tabs.forEach((tab) => {
    if (tab.classList.contains('active')) {
      tab.style.maxHeight = `${tab.scrollHeight + 15}px`;
    }
  });
});
