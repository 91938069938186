import Swiper, { Navigation, Autoplay, Scrollbar, Thumbs, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';
import { swiperObserver } from '../utils/swiper.utils.js';

function addActiveClass(event) {
  const buttons = document.querySelectorAll('.studiest-track__prev, .studiest-track__next');
  
  buttons.forEach(button => button.classList.remove('swiper-button-track__active'));
  
  event.target.classList.add('swiper-button-track__active');
}

// Our Services
let ourServicesSwiperWr = document.querySelectorAll('.our-services-slider-wr');

ourServicesSwiperWr?.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true, 
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scrollbarEl,
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        900: {
          spaceBetween: 24,
        },

      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    swiperObserver(swiper);
  }
});

// Case Studies

let caseStrudiesSwiperWr = document.querySelectorAll('.latest-projects-slider-wr');

caseStrudiesSwiperWr?.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true, 
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scrollbarEl,
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        900: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 32,
        }

      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });


    swiperObserver(swiper);
  }
});

// Clients

let clientsSwiperWr = document.querySelectorAll('.clients-swiper-wr');

clientsSwiperWr?.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true, 
      observeParents: true,
      spaceBetween: 0,
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      loopedSlides: 6,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scrollbarEl,
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        900: {
          loopedSlides: 4,
        },
        1200: {
          loopedSlides: 9,
        },
        1460: {
          loopedSlides: 10,
        },
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    swiperObserver(swiper);
  }
});

// Testimonails Slider

let testimonailsSwiperWr = document.querySelectorAll('.testimonials-swiper-wr');

testimonailsSwiperWr?.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true, 
      observeParents: true,
      spaceBetween: 8,
      slidesPerView: 1,
      loop: true,
      threshold: 10,
      loopedSlides: 3,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      scrollbar: {
        el: scrollbarEl,
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {
        640: {
          spaceBetween: 12,
        },
        900: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 32,
        }

      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    swiperObserver(swiper);
  }
});

// Our values Slider on resize

let valuesSwiperWr = document.querySelectorAll('.values-swiper-wr');
valuesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 12,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {},
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });


  }
});

// Services Slider on resize

let servicesSwiperWr = document.querySelectorAll('.services-swiper-wr');
servicesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');


    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 12,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      breakpoints: {},
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });


  }
});


// Studies sliders
let studiesFirstSwiperWr = document?.querySelectorAll('.studies-swipers-wr')[0];

if(studiesFirstSwiperWr) {
  const bottomSliderEl = studiesFirstSwiperWr.querySelector('.studies-track__slider'),
        topSliderEl = studiesFirstSwiperWr.querySelector('.studies-slider');

  if(bottomSliderEl && topSliderEl) {

    let bottomSlider = new Swiper(bottomSliderEl, {   
      modules: [Navigation, Scrollbar, Autoplay],
      observer: true, 
      observeParents: true,
      spaceBetween: 10,
      slidesPerView: 1,
      threshold: 10,
      loop: true,
      // loopedSlides: 3,
      watchSlidesProgress: true,
      breakpoints: {
        800: {
          spaceBetween: 16,
        }
      },
      // on: {
      //   afterInit: function () {
      //     wrapper.scrollWidth >= wrapper.clientWidth
      //       ? wrapper.classList.remove('justify-center')
      //       : wrapper.classList.add('justify-center');
      //   },
  
      //   resize: function () {
      //     wrapper.scrollWidth >= wrapper.clientWidth
      //       ? wrapper.classList.remove('justify-center')
      //       : wrapper.classList.add('justify-center');
      //   },
      // },
    });
  
  
    let topSlider = new Swiper(topSliderEl, {
      modules: [Navigation, Scrollbar, Autoplay, Thumbs],
      observer: true, 
      observeParents: true,
      spaceBetween: 16,
      slidesPerView: 1,
      threshold: 10,
      freeMode: true,
      watchSlidesProgress: true,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      navigation: {
        nextEl: '.studiest-track__next',
        prevEl: '.studiest-track__prev',
      },
      scrollbar: {
        el: '.studiest-track__scrollbar',
        draggable: true,
        snapOnRelease: true,
        dragSize: 'auto',
      },
      thumbs: {
        swiper: bottomSlider,
      },
      breakpoints: {},
    });
  
  // Додає обробники подій до кнопок навігації
  const prevButton = document.querySelector('.studiest-track__prev');
  const nextButton = document.querySelector('.studiest-track__next');
    
    prevButton.addEventListener('click', addActiveClass);
    nextButton.addEventListener('click', addActiveClass);
  }
}


// First Internal Service Slider

let internalServiceSwiperWr = document.querySelectorAll('.first-internal-service-swiper-wr');

internalServiceSwiperWr?.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let scrollbarEl = parentEl.querySelector('.swiper-scrollbar');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Navigation, Scrollbar, EffectCreative],
      observer: true, 
      observeParents: true,
      spaceBetween: 0,
      slidesPerView: 1,
      threshold: 10,
      effect: 'creative',
      creativeEffect: {
        limitProgress: 2,
        prev: {
          translate: [0, 0, -320],
          opacity: 0,
          effect: 'scale',
          scale: 0.6,
        },
        next: {
          /*translate: ['calc(100% - 20px)', 0, 0],*/
          translate: ['calc(25% - 22px)', 0, 0],
          opacity: 0.9,
          effect: 'scale',
          scale: 0.79,
        },
      },
      // autoplay: {
      //   // delay: 5000,
      //   // disableOnInteraction: false,
      //   // pauseOnMouseEnter: false,
      // },
      breakpoints: {},
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    swiperObserver(swiper);
  }
});