import './utils/vh.utils.js';
import './utils/animate-on-view.utils'

import './components/header.component.js';
import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';

import './components/sliders.component.js';
import './components/work-type.component.js';
import './components/accreditations.component.js';
import './components/date.component.js';
import './components/parallax.component.js';
import './components/case-studies.component.js'

import './libs/countUp.lib.js';

